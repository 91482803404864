.footer {
    background: #000d6b;
    padding-top: 50px;
    padding-bottom: 15px;
}

.footer__logo i {
    color: #fff;
    font-weight: 400;
}

.footer__logo span {
    color: #fff;
    font-weight: 500;
}

.footer__logo-content {
    color: rgba(255, 255, 255, 0.715);
    margin-top: 15px;
    line-height: 30px;
}

.quick__link {
    border: none;
    background: transparent !important;
}

.quick__link a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.715);
    font-weight: 300;
    transition: 0.3s;
}

.quick__link a:hover {
    color: #f9a826;
}

.footer__link-title {
    color: #fff;
}

.office__info {
    color: rgba(255, 255, 255, 0.715);
    font-weight: 300;
}

.newsletter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px solid #c8a972;
    border-radius: 50px;
    background: #020a4de9;
}

.newsletter input {
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
}

.newsletter span i {
    color: #fff;
}

.footer__bottom {
    border-top: 1px solid rgba(124, 138, 151, 0.18);
}
